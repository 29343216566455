// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import { globalEval } from 'jquery';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  // $('.section__family-issue__pages').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });
  // Category Filter
  $('#cat').change(function () {
    let dropdown = document.getElementById('cat');
    if (dropdown.options[dropdown.selectedIndex].value) {
      let category = dropdown.options[dropdown.selectedIndex].value;
      filterPosts(category);
    }
  });

  $('.js-faq-pagination .page-numbers').click(function (e) {
    $('html, body').animate(
      {
        scrollTop: $('html').offset().top + 100,
      },
      500
    );
    e.preventDefault();
    filterPosts('-1', $(this).attr('href'));
  });

  function filterPosts(category = '', paged = 1) {
    let data = {
      action: 'filter_posts',
      category: category,
      paged: paged,
      // eslint-disable-next-line no-undef
      nonce: ajax_object.nonce,
    };
    $('.faqs-list-js').fadeOut();
    // eslint-disable-next-line no-undef
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.faqs-list-js').html(response);
        $('.faqs-list-js').addClass('ajax-js');
        $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
          $('html, body').animate(
            {
              scrollTop: $('html').offset().top + 100,
            },
            500
          );
          e.preventDefault();
          filterPosts(category, $(this).attr('href'));
        });
        $('.faqs-list-js').fadeIn();
      }
    });
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  /*$(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });*/

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(this).scrollTop() >= 150) {
    $('.form-wrapper').addClass('form-wrapper--mobile');
  } else if ($(this).scrollTop() < 150) {
    $('.form-wrapper').removeClass('form-wrapper--mobile');
  }
});

$(document).ready(function () {
  let $bg_foot = $('.full_width_image').data('bg');
  let $bg_foot_2 = $('.full_width_image').data('bg-2');
  if ($(window).width() <= 640) {
    $('.full_width_image').css({
      'background-image': 'url(' + $bg_foot_2 + ')',
    });
  } else {
    $('.full_width_image').css({
      'background-image': 'url(' + $bg_foot + ')',
    });
  }
});
//
// $(document).ready(function () {
//   $('.desktop-image').css('display', 'none');
//   $('.mobile-image').css('display', 'none');
//
//   if ($(window).width() <= 640) {
//     $('.mobile-image').css('display', 'inline-block');
//   } else {
//     $('.desktop-image').css('display', 'inline-block');
//   }
// });

$(document).ready(function () {
  // Social proof slider
  $('.social-slider').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 867,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
    ],
  });

  // Practice Area slider
  $('.practice-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    asNavFor: '.practice-slider_asnavfor',
  });
  $('.practice-slider_asnavfor').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.practice-slider',
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: $('.practice-slider-navigation-anchor--prev'),
    nextArrow: $('.practice-slider-navigation-anchor--next'),
  });

  // Testimonials Slider
  $('.testimonials-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
  });

  // Search input in header menu
  let $iconSearch = $('.header__search-wrapper').find('#top-nav-search');
  let $topSearch = $('.header__navigation-wrapper').find('.search');
  let $searchSubmit = $('.header').find('.search__submit');
  let $searchClose = $('.header').find('.search__close-icon');

  $($searchSubmit).hide();

  $($iconSearch).click(function () {
    $($topSearch).toggle('slow');
  });

  $($searchClose).click(function () {
    $($topSearch).hide();
  });

  // Locations tabs
  $('.section__locations-buttons-inner').on('click', function () {
    $(this)
      .parents('.section__locations')
      .find('.section__locations-buttons-inner')
      .removeClass('active');
    $(this).addClass('active').fadeIn();
    let ind = $(this).index();
    $(this)
      .parents('.section__locations')
      .find('.section__locations-wrapper')
      .removeClass('active');
    $(this)
      .parents('.section__locations')
      .find('.section__locations-wrapper')
      .eq(ind)
      .addClass('active')
      .fadeIn();
  });

  // Show content after "Read More"
  let sectionBorder = $('.section__bio-page__biography--read-more');
  $('.section__bio-page__biography--extended').hide();
  $('.biography--read-more').on('click', function () {
    $('.section__bio-page__biography--extended').toggle('slow');
    sectionBorder.toggleClass('border');
    $('.biography--read-more').addClass('hide_block');
  });
  $('.biography--close-read-more').on('click', function () {
    $('.section__bio-page__biography--extended').hide('slow');
    sectionBorder.removeClass('border');
    $('.biography--read-more').removeClass('hide_block');
  });

  // Input icon disappear with type text
  $('.search__input').on('change paste keyup', function () {
    if ($(this).val().length > 0) {
      $(this).parent('.search').addClass('remove-icon');
    } else if (
      $(this).val().length === 0 &&
      $(this).parent('.search').hasClass('remove-icon')
    ) {
      $(this).parent('.search').removeClass('remove-icon');
    }
  });

  $('body').on('click', function () {
    setTimeout(function () {
      if ($('#offCanvasLeftOverlap').hasClass('is-open')) {
        $('body').addClass('overflow-hidden');
        $('.menu-icon').addClass('main-icon--close');
      } else {
        $('body').removeClass('overflow-hidden');
        $('.menu-icon').removeClass('main-icon--close');
      }
    }, 0);
  });

  $('.header-location-marker').on('click', function () {
    $('.locations-menu').toggleClass('visible');
  });
});

//Anchor button form

$('#contact-anchor').on('click', function () {
  let id = $(this).attr('href');
  $('html, body').animate(
    {
      scrollTop: $(id).offset().top - 200,
    },
    600
  );
});
// Sticky header
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 1) {
    $('.header').addClass('sticky');
  } else {
    $('.header').removeClass('sticky');
  }
});
